import {
  Col,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  InputNumber,
  DatePicker,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";

import notfound from "../../assets/images/not_found.png";
import SingleImageUpload from "../../components/SingleImageUpload";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { useAuthContext } from "../../context/AuthContext";

const AddForm = ({ api, show, hide, data, refresh }) => {
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const { language } = useAppContext();
  const [services, setServices] = useState([]);
  const [coverImage, setCoverImage] = useState();

  const { userProfile } = useAuthContext();
  const formData = Form.useWatch([], form);
  console.log(formData, "formData");
  const { selectedCategory } = useAuthContext();

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/webp",
    "image/gif",
  ];

  const getServices = (id) => {
    request({
      url: `${api.pkgService}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          setServices(data.data);
        }
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const onCreate = (values) => {
    setLoading(true);
    const { name, package_service_id, discount_price, original_price } = values;

    const payload = {
      name: name,
      package_service_id: package_service_id,
      discount_price: discount_price,
      original_price: original_price,
      cover_image: coverImage?.length ? coverImage : "",
    };

    request({
      url: `${data ? api.addEdit + "/" + data._id : api.addEdit}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleCoverImage = (data) => {
    data.length > 0 ? setCoverImage(data[0]?.url) : setCoverImage([]);
  };

  useEffect(() => {
    if (!data) return;

    console.log(data, "data");

    form.setFieldsValue({
      ...data,

      package_service_id: data?.package_service_id?.map(({ _id }) => _id),
      images: data.images,
    });
    setCoverImage(data.cover_image);
  }, [data]);

  useEffect(() => {
    if (!userProfile) return;
    console.log(userProfile, "userProfile");
    getServices();
  }, [userProfile, selectedCategory]);

  return (
    <Modal
      open={show}
      maskClosable={false}
      width={750}
      okText={data ? lang(`Update`) : lang(`Add`)}
      cancelText={lang(`Cancel`)}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal"
    >
      <Form
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
        initialValues={{
          is_active: true,
        }}
      >
        <h4 className="modal_title_cls">
          {data ? lang(`Edit Package`) : lang(`Add New Package`)}
        </h4>
        <Row gutter={[16, 0]}>
          <Col span={24} sm={12}>
            <Form.Item
              label={<> {lang(`Name`)}</>}
              name="name"
              rules={[
                {
                  required: true,
                  message: lang("Name is required"),
                },
                {
                  max: 200,
                  message: lang(
                    "Name should not contain more then 200 characters!"
                  ),
                },
                {
                  min: 2,
                  message: lang("Name should contain at least 2 characters!"),
                },
                {
                  validator: (_, value) => {
                    if (/\d/.test(value)) {
                      return Promise.reject(lang("Please enter a valid name!"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              normalize={(value) => value.trimStart()}
            >
              <Input autoComplete="off" placeholder={lang(`Enter Name`)} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"package_service_id"}
              label={lang("Service (atleast select 2 services)")}
              rules={[
                {
                  required: true,
                  message: lang("Please select a service"),
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
                placeholder={lang("Select Services")}
                showSearch
                mode="multiple"
              >
                {services?.map((service) => (
                  <Select.Option
                    key={service?._id}
                    label={service?.name}
                    value={service?._id}
                  >
                    {service?.name
                      ? service?.name
                      : language !== "en" && language !== null
                      ? service?.service_id?.[`${language}_name`] ??
                        service?.service_id?.name
                      : service?.service_id?.name}{" "}
                    {service?.discount_price
                      ? `(${service?.discount_price})`
                      : `(${service?.original_price})`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"discount_price"}
              label={lang("Discount Price (JOD)")}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const originalPrice = getFieldValue("original_price");

                    if (value !== undefined && value !== null) {
                      if (value <= 0) {
                        return Promise.reject(
                          new Error(
                            lang("Discounted price must be a positive number")
                          )
                        );
                      }

                      if (originalPrice && value >= originalPrice) {
                        return Promise.reject(
                          new Error(
                            lang(
                              "Discounted price must be less than the original price"
                            )
                          )
                        );
                      }
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder={lang("Enter Price")} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              name={"original_price"}
              label={lang("Original Price (JOD)")}
              rules={[
                {
                  required: true,
                  message: lang("Original price is required"),
                },
                {
                  type: "number",
                  min: 0.01,
                  message: lang("Original price must be a positive number"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const discountPrice = getFieldValue("discount_price");

                    if (discountPrice && value < discountPrice) {
                      return Promise.reject(
                        new Error(
                          lang(
                            "Original price must be greater than the discounted price"
                          )
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber placeholder={lang("Enter Price")} />
            </Form.Item>
          </Col>

          <Col span={24} md={24}>
            <Form.Item
              className="upload_wrap"
              name={"cover_image"}
              label={lang("Upload Thumbnail")}
            >
              <SingleImageUpload
                value={coverImage}
                fileType={FileType}
                imageType={"coverPhoto"}
                btnName={lang("Thumbnail")}
                onChange={(data) => handleCoverImage(data)}
                isDimension={false}
                size={5}
              />
            </Form.Item>

            {coverImage?.length ? (
              <div
                style={{ width: "100%" }}
                className="mt-2 add-img-product d-flex flex-column"
              >
                <Image width={120} src={coverImage || notfound} />
                <Button
                  style={{ width: "120px" }}
                  type="link"
                  color="danger"
                  onClick={() => {
                    handleCoverImage([]);
                  }}
                >
                  Delete
                </Button>
              </div>
            ) : (
              ""
            )}
            <p className="img-size-details">
              **
              {lang(
                "Images should be all size accepted for best view in thumbnail. You can select only (.gif, .png, .jpeg, .jpg) format files upto 5 MB file size"
              )}
              ..!!!
            </p>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
